import { Button, Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { StoreProptypes } from "../../PropTypes/StoreProptypes";
import { useEffect, useState } from "react";
import { websitePlanPropType } from "../../PropTypes/WebsitePlanPropType";
import encodeBase64 from "../../Helpers/encodeBase64";
import { saveNormalDomainService } from "../../Services/DomainService/AddNormalDomain";
import StrikePrice from "../../Helpers/strikePrice";

interface PropTypes {
    activePlanType: number, // 1-widget plan, 2- multi domain, 3 - Pre purchase
    activePlanInterval: string | null, // plan interval
    activePlanPrice: number // active plan price
    activePlanDomain: string, // active plan domain
    forAddNewDomain?: boolean // flag for add new domain
}

// component to render plan/pricing list
const UpgradePlanList = (props: PropTypes) => {

    // Redux Data
    const { currentWebsitePlanList, user, activeLanguage, accessToken, currentUserDiscount, currentWebsite } = useSelector((store: StoreProptypes) => store);
 console.log (`test: current website plan list ${JSON.stringify(currentWebsitePlanList, null, 2)}`);
    // Props
    const { activePlanPrice, activePlanInterval, activePlanType, activePlanDomain, forAddNewDomain } = props;

    // Functions
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // State
    const [upgradePlanList, setUpgradePlanList] = useState<websitePlanPropType[]>([]);
    const [planInterval, setPlanInterval] = useState<string>('Y');
    const [disabledButton, setDisabledButton] = useState<boolean>(false);
    const [freePlanID, setFreePlanID] = useState<number>();




    // Disable the "Select Plan" button for users in department 1
    useEffect(() => {
        // check if admin user
        if (user && user.department === 1) {
            setDisabledButton(true);
        } else {
            setDisabledButton(false);
        }
    }, [user])



    // Filter the plan list based on conditions and exclude plans with is_dynamic === 1
    useEffect(() => {

        // check if plan list available
        if (currentWebsitePlanList && Object.keys(currentWebsitePlanList).length > 0) {

            let tempPlanList: websitePlanPropType[] = [];

            // 1 = widget plan
            if (activePlanType === 1) {
                if (currentWebsite) {
                    // check if dynamic plan exists
                    if (currentWebsite.current_package_only[0].platforms === "dynamic") {
                        currentWebsitePlanList[`dynamic`]
                            .filter(planList => planList.is_dynamic !== 1) // Exclude is_dynamic=1
                            .forEach(planList => {
                                if ((activePlanInterval === "M" || activePlanInterval === null) && planList.monthly_price >= activePlanPrice && planList.id === currentWebsite.current_package_only[0].package_id) {
                                    tempPlanList = [...tempPlanList, planList];
                                } else if (activePlanInterval === "Y" && planList.price > activePlanPrice && planList.id === currentWebsite.current_package_only[0].package_id) {
                                    tempPlanList = [...tempPlanList, planList];
                                }
                            });

                        // check if plan list is empty
                        if (!tempPlanList.length) {
                            currentWebsitePlanList[`normal`]
                                .filter(planList => planList.is_dynamic !== 1) // Exclude is_dynamic=1
                                .forEach(planList => {
                                    if ((activePlanInterval === "M" || activePlanInterval === null) && planList.monthly_price >= activePlanPrice) {
                                        tempPlanList = [...tempPlanList, planList];
                                    } else if (activePlanInterval === "Y" && planList.monthly_price > activePlanPrice) {
                                        tempPlanList = [...tempPlanList, planList];
                                    }
                                });
                        }
                    } else {
                        currentWebsitePlanList[`normal`]
                            .filter(planList => planList.is_dynamic !== 1) // Exclude is_dynamic=1
                            .forEach(planList => {
                                if ((activePlanInterval === "M" || activePlanInterval === null) && planList.monthly_price >= activePlanPrice) {
                                    tempPlanList = [...tempPlanList, planList];
                                } else if (activePlanInterval === "Y" && planList.monthly_price > activePlanPrice) {
                                    tempPlanList = [...tempPlanList, planList];
                                }
                            })
                    }

                }
                else {
                    currentWebsitePlanList[`normal`].map((planList) => {
                        if ((activePlanInterval === "M" || activePlanInterval === null) && planList.monthly_price >= activePlanPrice) {
                            tempPlanList = [...tempPlanList, planList]
                        } else if (activePlanInterval === "Y" && planList.monthly_price > activePlanPrice) {
                            tempPlanList = [...tempPlanList, planList]
                        }
                    })
                }
            }
            // check if 2- multi domain plan
            else if (activePlanType === 2) {
                if (currentWebsite) {
                    if (currentWebsite.current_package_only[0].platforms === "dynamic") {
                        currentWebsitePlanList[`multi`]
                            .filter(planList => planList.is_dynamic !== 1) // Exclude is_dynamic=1
                            .forEach(planList => {
                                if (currentWebsite.current_package_only[0].package_id === planList.id) {
                                    tempPlanList = [...tempPlanList, planList];
                                }
                            });
                    } else {
                        currentWebsitePlanList[`multi`]
                            .filter(planList => planList.is_dynamic !== 1) // Exclude is_dynamic=1
                            .forEach(planList => {
                                if (activePlanInterval === "M" && planList.monthly_price >= activePlanPrice) {
                                    tempPlanList = [...tempPlanList, planList];
                                } else if (activePlanInterval === "Y" && planList.price > activePlanPrice) {
                                    tempPlanList = [...tempPlanList, planList];
                                }
                            });
                    }
                }
            }
            // check if 3 - Pre purchase plan
            else if (activePlanType === 3) {
                if (currentWebsite) {
                    if (currentWebsite.current_package_only[0].platforms === "dynamic") {
                        currentWebsitePlanList[`dynamic`]
                            .filter(planList => planList.is_dynamic !== 1) // Exclude is_dynamic=1
                            .forEach(planList => {
                                if ((activePlanInterval === "M" || activePlanInterval === null) && planList.monthly_price >= activePlanPrice && planList.id === currentWebsite.current_package_only[0].package_id) {
                                    tempPlanList = [...tempPlanList, planList];
                                } else if (activePlanInterval === "Y" && planList.price > activePlanPrice && planList.id === currentWebsite.current_package_only[0].package_id) {
                                    tempPlanList = [...tempPlanList, planList];
                                }
                            });
                    } else {


                        // Fall back to multi plans if no eligible dynamic plans are found    
                        if (!tempPlanList.length) {
                            currentWebsitePlanList[`multi`]
                                .filter(planList => planList.is_dynamic !== 1) // Exclude is_dynamic=1
                                .forEach(planList => {
                                    if (activePlanInterval === "M" && planList.monthly_price >= activePlanPrice) {
                                        tempPlanList = [...tempPlanList, planList];
                                    } else if (activePlanInterval === "Y" && planList.price > activePlanPrice) {
                                        tempPlanList = [...tempPlanList, planList];
                                    }
                                });
                        }
                    }
                }
                else {
                    currentWebsitePlanList[`multi`]
                        .filter(planList => planList.is_dynamic !== 1) // Exclude is_dynamic=1
                        .forEach(planList => {
                            if (activePlanInterval === "M" && planList.monthly_price >= activePlanPrice) {
                                tempPlanList = [...tempPlanList, planList];
                            } else if (activePlanInterval === "Y" && planList.price > activePlanPrice) {
                                tempPlanList = [...tempPlanList, planList];
                            }
                        });
                }
            }

            setUpgradePlanList(tempPlanList);

        }


    }, [activePlanType, activePlanInterval, activePlanPrice]);



    // Retrieve the ID of the free widget plan
    useEffect(() => {
        if (currentWebsitePlanList && currentWebsitePlanList["free-widget"]) {
            setFreePlanID(currentWebsitePlanList["free-widget"][0].id);
        }

    }, [currentWebsitePlanList])

    // Handle widget plan purchase
    const handlePurchaseWidgetPlan = (planID: number) => {
        if (user && activeLanguage && planID) {
            const urlString = `${planID}|${activePlanDomain}|${planInterval}|${user.id}|${activeLanguage.code}|1`;
            const encodedString = encodeBase64(urlString);
            navigate(`/front/package/${encodedString}`);
        }
    }

    // Widget multi-store Plan Purchase Event
    const handleMultiStoreWidgetPlan = (planID: number) => {
        if (user && activeLanguage && planID) {
            const urlString = `${planID}|${`multi-store`}|${planInterval}|${user.id}|${activeLanguage.code}|${5}`;
            const encodedString = encodeBase64(urlString);
            navigate(`/front/package/${encodedString}`);
        }
    }

    // Handle multi-domain widget plan purchase
    const handleMultiDomainWidgetPlan = (planID: number) => {
        if (user && activeLanguage && planID) {
            const urlString = `${planID}|${`multi`}|${planInterval}|${user.id}|${activeLanguage.code}|${3}`;
            const encodedString = encodeBase64(urlString);
            navigate(`/front/package/${encodedString}`);
        }
    }

    // Handle purchase of a normal domain plan for adding a new domain
    const handlePurchaseNormalDomainPlan = (planID: number) => {
        if (accessToken && user && planID && activeLanguage) {
            saveNormalDomainService(accessToken, activePlanDomain, user.id, planID, planInterval, activeLanguage.code, planID === freePlanID, dispatch, navigate, t)
        }
    }

    return (
        <>
            <div className="aioa_dashboard-widget-plan-list">
                {(currentWebsite
                    && (currentWebsite.current_package_only[0].platforms === "dynamic")
                    && (upgradePlanList.length)
                    && (upgradePlanList[0].id === currentWebsite.current_package_only[0].package_id))
                    ? <>
                    </>
                    : <>
                        {(activePlanInterval === "M" || activePlanInterval === null) && (currentWebsite?.current_package_only[0].slug !== "extra-large-site") && (
                            <>
                                <div className="aioa_dashboard-plan-list-toggle">
                                    <ul>
                                        <li><Button className={`${planInterval === 'M' ? `active` : ``}`} variant={`${planInterval === 'M' ? `primary` : `secondary`}`} onClick={() => setPlanInterval('M')}>{t('Pay Monthly')}</Button></li>
                                        <li><Button className={`${planInterval === 'Y' ? `active` : ``}`} variant={`${planInterval === 'Y' ? `primary` : `secondary`}`} onClick={() => setPlanInterval('Y')}>{t('Pay Yearly')}</Button></li>
                                    </ul>
                                </div>
                            </>
                        )}
                    </>
                }


                <Row className="gx-xl-0 gy-4" xs={1} md={2}
                    xl={(activePlanType === 2 || activePlanType === 3) ?
                        ((forAddNewDomain && user && user.department !== 4) ? 4 : 3) :
                        ((forAddNewDomain && user && user.department !== 4) ? 5 : 4)}>
                    {(upgradePlanList && upgradePlanList.length > 0) ? (
                        <>
                            {(forAddNewDomain && user && user.department !== 4) ?
                                <Col>
                                    <Card className="aioa_dashboard-widget-plan-box">
                                        <Card.Header className="h5">{t("Free Widget")}</Card.Header>
                                        <Card.Body>
                                            <div className="aioa_dashboard-widget-plan-box-description">{t('Plan Description Text', { pageViews: 10 })}</div>
                                            <div className="aioa_dashboard-widget-plan-box-price">
                                                <div className="aioa_dashboard-widget-plan-box-offer-price invisible">$0</div>
                                            </div>
                                            <div className="aioa_dashboard-widget-plan-box-btn">
                                                {freePlanID ? <Button variant="outline-primary" size="lg" onClick={() => handlePurchaseNormalDomainPlan(freePlanID)}>{t('Select Plan')}</Button> : ""}
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                : <></>}



                            {upgradePlanList.map((planItem) => {
                                return (
                                    <Col className={`${(activePlanType !== 2 && activePlanInterval === "M" && planInterval === 'M' && (planItem.monthly_price === activePlanPrice)) ? `d-none` : ``}`} key={planItem.id}>
                                        <Card className="aioa_dashboard-widget-plan-box">
                                            <Card.Header className="h5">{planItem.name}</Card.Header>
                                            <Card.Body>
                                                <div className="aioa_dashboard-widget-plan-box-description">{t('Plan Description Text', { pageViews: planItem.page_views })} </div>

                                                {activePlanType === 2 && (
                                                    <>
                                                        <div className="aioa_dashboard-widget-plan-domain-description">{t('plan domain info', { domainCount: planItem.slug === 'silver' ? 3 : planItem.slug === 'gold' ? 5 : planItem.domain_limit ? planItem.domain_limit : 10 })}</div>
                                                    </>
                                                )}

                                                {activePlanType === 3 && (
                                                    <>
                                                        <div className="aioa_dashboard-widget-plan-domain-description">{t('plan domain info', { domainCount: planItem.domain_limit || 0 })}</div>
                                                    </>
                                                )}

                                                <div className="aioa_dashboard-widget-plan-box-price">
                                                    {user && user.department === 4 && currentUserDiscount && currentUserDiscount.discount_value > 0 ? (
                                                        <>
                                                            {planInterval === "M" ? (
                                                                <>
                                                                    <div className="aioa_dashboard-widget-plan-box-strike-price"><del>${planItem.monthly_price.toFixed(2)}<span>/{t('Per Month')}</span></del></div>
                                                                    <div className="aioa_dashboard-widget-plan-box-offer-price">$<StrikePrice price={planItem.monthly_price} /><span>/{t('Per Month')}</span></div>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <div className="aioa_dashboard-widget-plan-box-strike-price">
                                                                        <del>
                                                                            ${planItem.price.toFixed(2)}
                                                                            <span>{t('Per Year')}</span>
                                                                        </del>
                                                                    </div>
                                                                    <div className="aioa_dashboard-widget-plan-box-offer-price">$<StrikePrice price={planItem.price} /><span>/{t('Per Year')}</span></div>
                                                                </>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <>
                                                            {planInterval === "M" ? (
                                                                <>
                                                                    <div className="aioa_dashboard-widget-plan-box-offer-price">${planItem.monthly_price}<span>/{t('Per Month')}</span></div>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <div className="aioa_dashboard-widget-plan-box-offer-price">${planItem.price}<span>/{t('Per Year')}</span></div>
                                                                </>
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                                <div className="aioa_dashboard-widget-plan-box-btn">
                                                    {forAddNewDomain ? (
                                                        <>
                                                            {activePlanType === 1 ? (
                                                                <>
                                                                    <Button variant="outline-primary" size="lg" onClick={() => handlePurchaseNormalDomainPlan(planItem.id)} disabled={disabledButton}>{t('Select Plan')}</Button>
                                                                </>
                                                            ) : (
                                                                <>

                                                                </>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <>
                                                            {activePlanType === 1 ? (
                                                                <>
                                                                    <Button variant="outline-primary" size="lg" onClick={() => handlePurchaseWidgetPlan(planItem.id)} disabled={disabledButton}>{t('Select Plan')}</Button>
                                                                </>
                                                            ) : activePlanType === 2 ? (
                                                                <>
                                                                    <Button variant="outline-primary" size="lg" onClick={() => handleMultiStoreWidgetPlan(planItem.id)} disabled={disabledButton}>{t('Select Plan')}</Button>
                                                                </>
                                                            ) : activePlanType === 3 ? (
                                                                <>
                                                                    <Button variant="outline-primary" 
                                                                    size="lg" 
                                                                    onClick={() =>
                                                                        activePlanPrice === 0
                                                                            ? handleMultiDomainWidgetPlan(planItem.id)
                                                                            : handleMultiStoreWidgetPlan(planItem.id)
                                                                    }
                                                                    disabled={disabledButton}>{t('Select Plan')}</Button>
                                                                </>
                                                            ) : (
                                                                <>

                                                                </>
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                )
                            })}
                        </>
                    ) : <>
                        {t('Contact us to upgrade or purchase a plan.')}
                    </>}
                </Row>
            </div>
        </>
    )
}

export default UpgradePlanList;
