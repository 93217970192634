import { LOADING, LOGIN_SUCCESS, LOGOUT, SET_ACCESS_TOKEN, SET_CURRENT_USER_STATUS, SET_CURRENT_WEBSITE, SET_MESSAGE, SET_WEBSITE_LIST } from "../../Actions/Types";
import { AutoLoginApi } from "../../Api/UserAction/AutoLoginApi";
import decodeBase64 from "../../Helpers/decodeBase64";
import encodeBase64 from "../../Helpers/encodeBase64";
import { WebsiteListPropTypes } from "../../PropTypes/WebsitePropTypes";
import { DomainDetailsService } from "../DomainService/DomainDetails";
import { getPlanListService } from "../PlanListService";
import { getUserDetailsService } from "./LoginActionService";

// service to perform auto login
const autoLoginService = (
    token: string, // access token
    dispatch: Function, // function to store redux data
    navigate: Function, // navigate function
    t: Function, // i18next t function
    activeLanguageCodeString?: string //language code 
) => {
    // Loading
    dispatch({
        type: LOADING,
        payload: true,
    });

    // Token action
    const newToken = decodeBase64(token).split("|");
    const newTokenWebsiteTemp = newToken[0].replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('/')[0];
    const newTokenWebsite = newTokenWebsiteTemp.toLocaleLowerCase();
    const dist = newToken[1]; // redirect destination

    let userId;
    let newPID: string;
    let newInterval: string;
    let newUserID: string;
    let newPlanInterval: string;
    let newiFrameDist: string;

    // checks if website name does not exist and token array contains two items
    if (newTokenWebsite === '-' && newToken.length > 2) {
        userId = newToken[2];
    } else {
        newPID = newToken[2];
        newInterval = newToken[3];
        newUserID = newToken[4];
        newPlanInterval = newToken[5];
        newiFrameDist = newToken[6];
    }

    // API to perform auto login
    AutoLoginApi(encodeBase64(newToken[0]), userId).then((autoLoginRes) => {

        // checks if Auto Login Response data exist and access token received
        if (autoLoginRes.Data && autoLoginRes.token) {

            // service to get Current User Plans
            getPlanListService(autoLoginRes.token, 2, dispatch);

            let currentUserType: number;

            // check if user is an extension user
            if (autoLoginRes.Data.is_extension_user === 1) {
                currentUserType = 5
            }
            // check if user is a partner user
            else if (autoLoginRes.Data.department === 4) {
                // check if partner user has multi domain plan
                if (autoLoginRes.Data.is_multiple_domain === 1) {
                    currentUserType = 3
                } else {
                    currentUserType = 2
                }
            }
            // check if normal user has multi domain plan
            else if (autoLoginRes.Data.is_multiple_domain === 2) {
                currentUserType = 4
            }
            else {
                currentUserType = 1
            }

            // Set User type
            dispatch({
                type: SET_CURRENT_USER_STATUS,
                payload: {
                    currentUserType: currentUserType,
                    isInitUser: autoLoginRes.Data.website_lists.length === 0 ? true : false
                },
            });

            // Save access token
            dispatch({
                type: SET_ACCESS_TOKEN,
                payload: autoLoginRes.token,
            });

            // Save User Data
            dispatch({
                type: LOGIN_SUCCESS,
                payload: { user: autoLoginRes.Data },
            });

            // Set Website List
            dispatch({
                type: SET_WEBSITE_LIST,
                payload: autoLoginRes.Data.website_lists
            });

            // Set Current website ID
            let currentWebsiteID: number;

            // check if website list is available
            if (autoLoginRes.Data.website_lists.length > 0) {
                // check if website name exists
                if (newTokenWebsite && newTokenWebsite !== '-') {
                    // loop through website list and get website ID
                    const tempCurrentWebsiteID = (autoLoginRes.Data.website_lists).filter((domainFilterData: WebsiteListPropTypes) => ((domainFilterData.domain).toLocaleLowerCase()) === newTokenWebsite).map((filterObj: WebsiteListPropTypes) => {
                        return filterObj.id
                    })
                    currentWebsiteID = tempCurrentWebsiteID[0];
                } else {
                    currentWebsiteID = autoLoginRes.Data.website_lists[0].id;
                }
                // service to get domain details
                DomainDetailsService(autoLoginRes.token, currentWebsiteID, dispatch, t).then((res) => {
                    //console.log("from auto login service");
                    // check if response is ok
                    if (res.status === 200) {
                                                
                        // check if redirect destination exists
                        if (dist && dist !== '0') {

                            // if destination is 1, redirect to scripts page
                            if (dist === '1') {
                                // Code Screen
                                navigate("/front/scripts");

                            }
                            // if destination is 2, redirect to package purchase page
                            else if (dist === '2') {
                                if (autoLoginRes.current_plan_id) {
                                    const pId = autoLoginRes.current_plan_id;
                                    const urlString = `${pId}|${newTokenWebsite}|Y|${autoLoginRes.Data.id}|${activeLanguageCodeString ? activeLanguageCodeString : `en`}|1`;
                                    const planEncodedString1 = encodeBase64(urlString);
                                    // Checkout
                                    navigate(`/front/package/${planEncodedString1}`);
                                }
                            }
                            // if destination is uc, redirect to package page
                            else if (dist === 'uc') {
                                const pId = autoLoginRes.current_plan_id;
                                const urlString = `${newPID}|${newTokenWebsite}|${newPlanInterval}|${autoLoginRes.Data.id}|${newPlanInterval}|7`;
                                const planEncodedString1 = encodeBase64(urlString);
                                navigate(`/front/package/${planEncodedString1}`);
                            }
                            // if destination is 15, redirect to package page
                            else if (dist === '15') {
                                if (autoLoginRes.current_plan_id) {
                                    const pId = autoLoginRes.current_plan_id;
                                    const urlString = `${pId}|${newTokenWebsite}|Y|${autoLoginRes.Data.id}|${activeLanguageCodeString ? activeLanguageCodeString : `en`}|5`;
                                    const planEncodedString1 = encodeBase64(urlString);
                                    // Checkout
                                    navigate(`/front/package/${planEncodedString1}`);
                                }
                            }
                            // if destination is 3, redirect to order page
                            else if (dist === '3') {
                                // order screen
                                navigate(`/front/orders?is_new=true&status=success&wtoken=${encodeBase64(currentWebsiteID.toString())}`);

                            }
                            // if destination is 3, redirect to widget settings page
                            else if (dist === '5') {
                                // order screen
                                navigate("/front/widget-settings");

                            }
                            // if destination is 6, and user type is partner, get user details and redirect to dashboard page
                            else if (dist === '6' && autoLoginRes.Data.department === 4) {
                                // order screen
                                navigate("/front");

                                // service to get user details
                                getUserDetailsService(autoLoginRes.token, dispatch, t).then((userRes) => {
                                    // checks if response is ok
                                    if (userRes.status === 200) {
                                        navigate('/front')
                                    }
                                })

                            }
                            // if destination is 7, redirect to website list page
                            else if (dist === '7') {
                                navigate('/front/websites')

                                // service to get user details
                                getUserDetailsService(autoLoginRes.token, dispatch, t).then((userRes) => {
                                    if (userRes.status === 200) {
                                        navigate('/front/websites')
                                    }
                                })
                            }
                            // if destination is 004, redirect to website list page
                            else if (dist === '004') {
                                navigate("/front/websites");
                            }
                            // if destination is 007, redirect to widget settings page for iframe
                            else if (dist === '007') {
                                navigate("/front/web-widget-settings");
                            }
                            // if destination is 008, redirect to my scan page
                            else if (dist === '008') {
                                //console.info("my-scan call...");
                                navigate("/front/my-scan");
                            }
                            // if destination is ad1, redirect to white label page
                            else if (dist === 'ad1') {
                                navigate("/front/addon-services/white-label-service");
                            }
                            // if destination is ad2, redirect to custom branding page
                            else if (dist === 'ad2') {
                                navigate("/front/addon-services/custom-branding-service");
                            }
                            // if destination is ad3, redirect to modify accessibility menu page
                            else if (dist === 'ad3') {
                                navigate("/front/addon-services/modify-accessibility-menu");
                            }
                            // if destination is ad4, redirect to live site translation page
                            else if (dist === 'ad4') {
                                navigate("/front/addon-services/live-site-translation");
                            }
                            // if destination is ad5, redirect to pdf remediation page
                            else if (dist === 'ad5') {
                                navigate("/front/addon-services/pdfs");
                            }
                            // if destination is ad6, redirect to manual audit report page
                            else if (dist === 'ad6') {
                                navigate("/front/addon-services/manual-audit-report");
                            } 
                            // if destination is ad7, redirect to VPAT report page
                            else if (dist === 'ad7') {
                                navigate("/front/addon-services/vpat-report");
                            } 
                            // if destination is ad8, redirect to manual remediation page
                            else if (dist === 'ad8') {
                                navigate("/front/addon-services/manual-remediation");
                            } 
                            // if destination is ad9, redirect to design audit page
                            else if (dist === 'ad9') {
                                navigate("/front/addon-services/design-audit");
                            } 
                            // if destination is ad10, redirect to native mobile app audit page
                            else if (dist === 'ad10') {
                                navigate("/front/addon-services/native-mobile-app-audit");
                            } 
                            // if destination is ad11, redirect to web app/SPA audit page
                            else if (dist === 'ad11') {
                                navigate("/front/addon-services/web-app-spa-audit");
                            } else {

                            }
                        } else {
                            // Navigation
                            navigate("/front");
                        }


                        // remove Loading
                        dispatch({
                            type: LOADING,
                            payload: false,
                        });

                        // dispatch login successful message
                        dispatch({
                            type: SET_MESSAGE,
                            payload: {
                                "toast_message": t('Login Success Msg'),
                                "toast_type": "success",
                                "onclose": null
                            }
                        })

                    } else {

                        // remove Loading
                        dispatch({
                            type: LOADING,
                            payload: false,
                        });

                    }

                });
            }
            else {

                // navigate to getting started screen if no domain found
                navigate(`/front/getting-started?user_update=true`);
                
                // Set Website List
                dispatch({
                    type: SET_WEBSITE_LIST,
                    payload: null
                });

                // Set Current Website Data
                dispatch({
                    type: SET_CURRENT_WEBSITE,
                    payload: null
                });

                // Set Loading
                dispatch({
                    type: LOADING,
                    payload: false,
                });
            }


        } else {

            // perform Logout if no user data is found
            dispatch({
                type: LOGOUT,
            });

            navigate("/front/login");

            // Loading
            dispatch({
                type: LOADING,
                payload: false,
            });

            // Res Msg
            dispatch({
                type: SET_MESSAGE,
                payload: {
                    "toast_message": t('Login Fail Msg'),
                    "toast_type": "danger",
                    "onclose": null
                }
            });

        }

    })

}

export { autoLoginService }
