import { Card } from "react-bootstrap"
import { useSelector } from "react-redux";
import { StoreProptypes } from "../../PropTypes/StoreProptypes";
import parseISODate from "../../Helpers/parseISODate";
import { useTranslation } from "react-i18next";
import PaymentButtonBlock from "../PaymentButton";
import { useLocation } from "react-router-dom";

const NormalUserCurrentTrialPlanInfo = () => { // only Normal user Plan Details

    // Functions
    const { t } = useTranslation();
    const location = useLocation();

    // Redux Data
    const { currentWebsite, user } = useSelector((store: StoreProptypes) => store);
    console.log("hello from CurrentTrialPlanInfo");

    return (
        <>
            {currentWebsite && currentWebsite.current_package_only && currentWebsite.current_package_only.length > 0 && (
                <>
                    <Card className="aioa_dashboard-plan-info-box normal-user trial-plan-box">
                        <Card.Body>
                            <div className="aioa_dashboard-plan-info-box-label">{t('Trial Plan Label')}</div>
                            <div className="aioa_dashboard-plan-info-box-left">
                                <h4 className="text-primary mb-1">{currentWebsite.current_package_only[0].name} Plan</h4>
                                {currentWebsite.current_package_only[0].cancel_date ? (
                                    <>
                                        <p className="text-danger"><strong>{t(`Current trial plan expired on ${parseISODate(currentWebsite.current_package_only[0].end_date)}`)}.</strong></p>
                                    </>
                                ) : (
                                    <>
                                        {user && user.department === 4 ? (
                                            <>
                                                <p>{t(`Trial plan will expire on ${parseISODate(currentWebsite.current_package_only[0].end_date)}`)}. After trial date, ${(currentWebsite.current_package_only[0].final_price).toFixed(2)}/{currentWebsite.current_package_only[0].subscr_interval === 'M' ? t('Per Month') : t('Per Year')} would be charged.</p>
                                            </>
                                        ) : (
                                            <>
                                                <p>{t(`Trial plan will expire on ${parseISODate(currentWebsite.current_package_only[0].end_date)}`)}. After trial date, ${(currentWebsite.current_package_only[0].final_price).toFixed(2)}/{currentWebsite.current_package_only[0].subscr_interval === 'M' ? t('Per Month') : t('Per Year')} would be charged.</p>
                                            </>
                                        )}
                                    </>
                                )}
                            </div>
                            <div className="aioa_dashboard-plan-info-box-right">
                                <div className="aioa_dashboard-plan-info-box-price">
                                    {currentWebsite.current_package_only[0].subscr_interval === 'Y' ? (
                                        <>
                                            <strong className="original-price">${(currentWebsite.current_package_only[0].final_price).toFixed(2)}<span>/{t('Per Year')}</span></strong>
                                        </>
                                    ) : (
                                        <>
                                            <strong className="original-price">${(currentWebsite.current_package_only[0].final_price).toFixed(2)}<span>/{t('Per Month')}</span></strong>
                                        </>
                                    )}
                                </div>
                                <div className="aioa_dashboard-plan-info-box-btn">
                                    {(location.pathname === '/front/settings' || location.pathname === '/front/settings/') ? (
                                        <>
                                            <PaymentButtonBlock
                                                buttonType={3}
                                                activePlanType={1}
                                                activePlanDomain={currentWebsite.domain}
                                                activePlanInterval={currentWebsite.current_package_only[0].subscr_interval}
                                                activePlanPrice={currentWebsite.current_package_only[0].final_price}
                                                activePlanOfferPrice={currentWebsite.cancel_discount_price}
                                                activePlanOfferPr={currentWebsite.cancel_discount_percentage}
                                                websiteID={currentWebsite.id}
                                                activeUserID={currentWebsite.user_id}
                                                activePlanID={currentWebsite.current_package_only[0].package_id}
                                                buttonBigSize={false}
                                                buttonVariant={`link`}
                                                buttonLabel={`Cancel Plan`}
                                                isShowDiscount={currentWebsite.current_package_only[0].is_cancel_offer_accept !== 1 ? true : false}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            {currentWebsite.current_package_only[0].is_cancel_offer_accept !== 1 && (
                                                <>
                                                    <PaymentButtonBlock
                                                        buttonType={3}
                                                        activePlanType={1}
                                                        activePlanDomain={currentWebsite.domain}
                                                        activePlanInterval={currentWebsite.current_package_only[0].subscr_interval}
                                                        activePlanPrice={currentWebsite.current_package_only[0].final_price}
                                                        activePlanOfferPrice={currentWebsite.cancel_discount_price}
                                                        activePlanOfferPr={currentWebsite.cancel_discount_percentage}
                                                        websiteID={currentWebsite.id}
                                                        activeUserID={currentWebsite.user_id}
                                                        activePlanID={currentWebsite.current_package_only[0].package_id}
                                                        buttonBigSize={false}
                                                        buttonVariant={`link`}
                                                        buttonLabel={`Cancel Plan`}
                                                        isShowDiscount={currentWebsite.current_package_only[0].is_cancel_offer_accept !== 1 ? true : false}
                                                    />
                                                </>
                                            )}
                                        </>
                                    )}

                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </>
            )}
        </>
    )
}

const PrePurchasedPartnerUserCurrentTrialPlanInfo = () => { // only PrePurchased Partner user Plan Details

    // Functions
    const { t } = useTranslation();
    const location = useLocation();

    // Redux Data
    const { currentWebsite, user } = useSelector((store: StoreProptypes) => store);

    return (
        <>
            {currentWebsite && currentWebsite.current_package_only && currentWebsite.current_package_only.length > 0 && (
                <>
                    <Card className="aioa_dashboard-plan-info-box prepurchased-Partner trial-plan-box">
                        <Card.Body>
                            <div className="aioa_dashboard-plan-info-box-label">{t('Trial Plan Label')}</div>
                            <div className="aioa_dashboard-plan-info-box-left">
                                <h4 className="text-primary mb-1">{currentWebsite.current_package_only[0].name} Plan</h4>
                                {currentWebsite.current_package_only[0].cancel_date ? (
                                    <>
                                        <p className="text-danger"><strong>{t(`Current trial plan expired on ${parseISODate(currentWebsite.current_package_only[0].end_date)}`)}.</strong></p>
                                    </>
                                ) : (
                                    <>
                                        {user && user.department === 4 ? (
                                            <>
                                                <p>{t(`Trial plan will expire on ${parseISODate(currentWebsite.current_package_only[0].end_date)}`)}. After trial date, ${(currentWebsite.current_package_only[0].final_price).toFixed(2)}/{currentWebsite.current_package_only[0].subscr_interval === 'M' ? t('Per Month') : t('Per Year')} would be charged.</p>
                                            </>
                                        ) : (
                                            <>
                                                <p>{t(`Trial plan will expire on ${parseISODate(currentWebsite.current_package_only[0].end_date)}`)}. After trial date, ${(currentWebsite.current_package_only[0].final_price).toFixed(2)}/{currentWebsite.current_package_only[0].subscr_interval === 'M' ? t('Per Month') : t('Per Year')} would be charged.</p>
                                            </>
                                        )}
                                    </>
                                )}
                            </div>
                            <div className="aioa_dashboard-plan-info-box-right">
                                <div className="aioa_dashboard-plan-info-box-price">
                                    {currentWebsite.current_package_only[0].subscr_interval === 'Y' ? (
                                        <>
                                            <strong className="original-price">${(currentWebsite.current_package_only[0].final_price).toFixed(2)}<span>/{t('Per Year')}</span></strong>
                                        </>
                                    ) : (
                                        <>
                                            <strong className="original-price">${(currentWebsite.current_package_only[0].final_price).toFixed(2)}<span>/{t('Per Month')}</span></strong>
                                        </>
                                    )}
                                </div>
                                <div className="aioa_dashboard-plan-info-box-btn">
                                    {(location.pathname === '/front/settings' || location.pathname === '/front/settings/') ? (
                                        <>
                                            <PaymentButtonBlock
                                                buttonType={3}
                                                activePlanType={3}
                                                activePlanDomain={currentWebsite.domain}
                                                activePlanInterval={currentWebsite.current_package_only[0].subscr_interval}
                                                activePlanPrice={currentWebsite.current_package_only[0].final_price}
                                                activePlanOfferPrice={currentWebsite.cancel_discount_price}
                                                activePlanOfferPr={currentWebsite.cancel_discount_percentage}
                                                websiteID={currentWebsite.id}
                                                activeUserID={currentWebsite.user_id}
                                                activePlanID={currentWebsite.current_package_only[0].package_id}
                                                buttonBigSize={false}
                                                buttonVariant={`link`}
                                                buttonLabel={`Cancel Plan`}
                                                isShowDiscount={currentWebsite.current_package_only[0].is_cancel_offer_accept !== 1 ? true : false}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            {currentWebsite.current_package_only[0].is_cancel_offer_accept !== 1 && (
                                                <>
                                                    <PaymentButtonBlock
                                                        buttonType={3}
                                                        activePlanType={3}
                                                        activePlanDomain={currentWebsite.domain}
                                                        activePlanInterval={currentWebsite.current_package_only[0].subscr_interval}
                                                        activePlanPrice={currentWebsite.current_package_only[0].final_price}
                                                        activePlanOfferPrice={currentWebsite.cancel_discount_price}
                                                        activePlanOfferPr={currentWebsite.cancel_discount_percentage}
                                                        websiteID={currentWebsite.id}
                                                        activeUserID={currentWebsite.user_id}
                                                        activePlanID={currentWebsite.current_package_only[0].package_id}
                                                        buttonBigSize={false}
                                                        buttonVariant={`link`}
                                                        buttonLabel={`Cancel Plan`}
                                                        isShowDiscount={currentWebsite.current_package_only[0].is_cancel_offer_accept !== 1 ? true : false}
                                                    />
                                                </>
                                            )}
                                        </>
                                    )}

                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </>
            )}
        </>
    )
}

const NormalPartnerUserCurrentTrialPlanInfo = () => { // only Normal Partner user Plan Details

    // Functions
    const { t } = useTranslation();
    const location = useLocation();

    // Redux Data
    const { currentWebsite, user } = useSelector((store: StoreProptypes) => store);

    return (
        <>
            {currentWebsite && currentWebsite.current_package_only && currentWebsite.current_package_only.length > 0 && (
                <>
                    <Card className="aioa_dashboard-plan-info-box normal-partner trial-plan-box">
                        <Card.Body>
                            <div className="aioa_dashboard-plan-info-box-label">{t('Trial Plan Label')}</div>
                            <div className="aioa_dashboard-plan-info-box-left">
                                <h4 className="text-primary mb-1">{currentWebsite.current_package_only[0].name} Plan</h4>
                                {currentWebsite.current_package_only[0].cancel_date ? (
                                    <>
                                        <p className="text-danger"><strong>{t(`Current trial plan expired on ${parseISODate(currentWebsite.current_package_only[0].end_date)}`)}.</strong></p>
                                    </>
                                ) : (
                                    <>
                                        {user && user.department === 4 ? (
                                            <>
                                                <p>{t(`Trial plan will expire on ${parseISODate(currentWebsite.current_package_only[0].end_date)}`)}. After trial date, ${(currentWebsite.current_package_only[0].final_price).toFixed(2)}/{currentWebsite.current_package_only[0].subscr_interval === 'M' ? t('Per Month') : t('Per Year')} would be charged.</p>
                                            </>
                                        ) : (
                                            <>
                                                <p>{t(`Trial plan will expire on ${parseISODate(currentWebsite.current_package_only[0].end_date)}`)}. After trial date, ${(currentWebsite.current_package_only[0].final_price).toFixed(2)}/{currentWebsite.current_package_only[0].subscr_interval === 'M' ? t('Per Month') : t('Per Year')} would be charged.</p>
                                            </>
                                        )}
                                    </>
                                )}
                            </div>
                            <div className="aioa_dashboard-plan-info-box-right">
                                <div className="aioa_dashboard-plan-info-box-price">
                                    {currentWebsite.current_package_only[0].subscr_interval === 'Y' ? (
                                        <>
                                            <strong className="original-price">${(currentWebsite.current_package_only[0].final_price).toFixed(2)}<span>/{t('Per Year')}</span></strong>
                                        </>
                                    ) : (
                                        <>
                                            <strong className="original-price">${(currentWebsite.current_package_only[0].final_price).toFixed(2)}<span>/{t('Per Month')}</span></strong>
                                        </>
                                    )}
                                </div>
                                <div className="aioa_dashboard-plan-info-box-btn">

                                    {(location.pathname === '/front/settings' || location.pathname === '/front/settings/') ? (
                                        <>
                                            <PaymentButtonBlock
                                                buttonType={3}
                                                activePlanType={1}
                                                activePlanDomain={currentWebsite.domain}
                                                activePlanInterval={currentWebsite.current_package_only[0].subscr_interval}
                                                activePlanPrice={currentWebsite.current_package_only[0].final_price}
                                                activePlanOfferPrice={currentWebsite.cancel_discount_price}
                                                activePlanOfferPr={currentWebsite.cancel_discount_percentage}
                                                websiteID={currentWebsite.id}
                                                activeUserID={currentWebsite.user_id}
                                                activePlanID={currentWebsite.current_package_only[0].package_id}
                                                buttonBigSize={false}
                                                buttonVariant={`link`}
                                                buttonLabel={`Cancel Plan`}
                                                isShowDiscount={currentWebsite.current_package_only[0].is_cancel_offer_accept !== 1 ? true : false}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            {currentWebsite.current_package_only[0].is_cancel_offer_accept !== 1 && (
                                                <>
                                                    <PaymentButtonBlock
                                                        buttonType={3}
                                                        activePlanType={1}
                                                        activePlanDomain={currentWebsite.domain}
                                                        activePlanInterval={currentWebsite.current_package_only[0].subscr_interval}
                                                        activePlanPrice={currentWebsite.current_package_only[0].final_price}
                                                        activePlanOfferPrice={currentWebsite.cancel_discount_price}
                                                        activePlanOfferPr={currentWebsite.cancel_discount_percentage}
                                                        websiteID={currentWebsite.id}
                                                        activeUserID={currentWebsite.user_id}
                                                        activePlanID={currentWebsite.current_package_only[0].package_id}
                                                        buttonBigSize={false}
                                                        buttonVariant={`link`}
                                                        buttonLabel={`Cancel Plan`}
                                                        isShowDiscount={currentWebsite.current_package_only[0].is_cancel_offer_accept !== 1 ? true : false}
                                                    />
                                                </>
                                            )}
                                        </>
                                    )}

                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </>
            )}
        </>
    )
}

const MultiStoreUserCurrentTrialPlanInfo = () => { // only Multi Store user Plan Details

    // Functions
    const { t } = useTranslation();
    const location = useLocation();

    // Redux Data
    const { currentWebsite, user } = useSelector((store: StoreProptypes) => store);

    return (
        <>
            {currentWebsite && currentWebsite.current_package_only && currentWebsite.current_package_only.length > 0 && (
                <>
                    <Card className="aioa_dashboard-plan-info-box multi-store-user trial-plan-box">
                        <Card.Body>
                            <div className="aioa_dashboard-plan-info-box-label">{t('Trial Plan Label')}</div>
                            <div className="aioa_dashboard-plan-info-box-left">
                                <h4 className="text-primary mb-1">{currentWebsite.current_package_only[0].name} Plan</h4>
                                {currentWebsite.current_package_only[0].cancel_date ? (
                                    <>
                                        <p className="text-danger"><strong>{t(`Current trial plan expired on ${parseISODate(currentWebsite.current_package_only[0].end_date)}`)}.</strong></p>
                                    </>
                                ) : (
                                    <>
                                        {user && user.department === 4 ? (
                                            <>
                                                <p>{t(`Trial plan will expire on ${parseISODate(currentWebsite.current_package_only[0].end_date)}`)}. After trial date, ${(currentWebsite.current_package_only[0].final_price).toFixed(2)}/{currentWebsite.current_package_only[0].subscr_interval === 'M' ? t('Per Month') : t('Per Year')} would be charged.</p>
                                            </>
                                        ) : (
                                            <>
                                                <p>{t(`Trial plan will expire on ${parseISODate(currentWebsite.current_package_only[0].end_date)}`)}. After trial date, ${(currentWebsite.current_package_only[0].final_price).toFixed(2)}/{currentWebsite.current_package_only[0].subscr_interval === 'M' ? t('Per Month') : t('Per Year')} would be charged.</p>
                                            </>
                                        )}
                                    </>
                                )}
                            </div>
                            <div className="aioa_dashboard-plan-info-box-right">
                                <div className="aioa_dashboard-plan-info-box-price">
                                    {currentWebsite.current_package_only[0].subscr_interval === 'Y' ? (
                                        <>
                                            <strong className="original-price">${(currentWebsite.current_package_only[0].final_price).toFixed(2)}<span>/{t('Per Year')}</span></strong>
                                        </>
                                    ) : (
                                        <>
                                            <strong className="original-price">${(currentWebsite.current_package_only[0].final_price).toFixed(2)}<span>/{t('Per Month')}</span></strong>
                                        </>
                                    )}
                                </div>
                                <div className="aioa_dashboard-plan-info-box-btn">
                                    {(location.pathname === '/front/settings' || location.pathname === '/front/settings/') ? (
                                        <>
                                            <PaymentButtonBlock
                                                buttonType={3}
                                                activePlanType={2}
                                                activePlanDomain={currentWebsite.domain}
                                                activePlanInterval={currentWebsite.current_package_only[0].subscr_interval}
                                                activePlanPrice={currentWebsite.current_package_only[0].final_price}
                                                activePlanOfferPrice={currentWebsite.cancel_discount_price}
                                                activePlanOfferPr={currentWebsite.cancel_discount_percentage}
                                                websiteID={currentWebsite.id}
                                                activeUserID={currentWebsite.user_id}
                                                activePlanID={currentWebsite.current_package_only[0].package_id}
                                                buttonBigSize={false}
                                                buttonVariant={`link`}
                                                buttonLabel={`Cancel Plan`}
                                                isShowDiscount={currentWebsite.current_package_only[0].is_cancel_offer_accept !== 1 ? true : false}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            {currentWebsite.current_package_only[0].is_cancel_offer_accept !== 1 && (
                                                <>
                                                    <PaymentButtonBlock
                                                        buttonType={3}
                                                        activePlanType={2}
                                                        activePlanDomain={currentWebsite.domain}
                                                        activePlanInterval={currentWebsite.current_package_only[0].subscr_interval}
                                                        activePlanPrice={currentWebsite.current_package_only[0].final_price}
                                                        activePlanOfferPrice={currentWebsite.cancel_discount_price}
                                                        activePlanOfferPr={currentWebsite.cancel_discount_percentage}
                                                        websiteID={currentWebsite.id}
                                                        activeUserID={currentWebsite.user_id}
                                                        activePlanID={currentWebsite.current_package_only[0].package_id}
                                                        buttonBigSize={false}
                                                        buttonVariant={`link`}
                                                        buttonLabel={`Cancel Plan`}
                                                        isShowDiscount={currentWebsite.current_package_only[0].is_cancel_offer_accept !== 1 ? true : false}
                                                    />
                                                </>
                                            )}
                                        </>
                                    )}

                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </>
            )}
        </>
    )
}

const AllUserOnlyTrialPlanInfo = () => {

    // Redux Data
    const { currentWebsite, user } = useSelector((store: StoreProptypes) => store);

    return (
        <>
            {currentWebsite && (
                <>
                    {user && (
                        <>
                            {user.department === 4 ? ( // For Partner User Only
                                <>
                                    {(user.is_multiple_domain === 1) ? ( // Pre purchased Partner Show only plan
                                        <>
                                            <PrePurchasedPartnerUserCurrentTrialPlanInfo />
                                        </>
                                    ) : ( // Normal Partner plan with upgrade one step up
                                        <>
                                            <NormalPartnerUserCurrentTrialPlanInfo />
                                        </>
                                    )}
                                </>
                            ) : (
                                <>
                                    {user.is_multiple_domain === 2 ? ( // Sliver Gold Plan with Add New plan 
                                        <>
                                            <MultiStoreUserCurrentTrialPlanInfo />
                                        </>
                                    ) : ( // Normal Plan with one stepup upgrade option
                                        <>
                                            <NormalUserCurrentTrialPlanInfo />
                                        </>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </>
            )}

        </>
    )
}

export default AllUserOnlyTrialPlanInfo;