import { useTranslation } from 'react-i18next';
import '../../Assets/scss/components/init-msg-block.scss';
import { Link } from 'react-router-dom';
import PaymentButtonBlock from '../PaymentButton';
import { useSelector } from 'react-redux';
import { StoreProptypes } from '../../PropTypes/StoreProptypes';

const ExpiredVersionOverlay = () => {

    const { t } = useTranslation();

    // Redux Data
    const { currentWebsite, currentWebsitePlanStatus, user } = useSelector((store: StoreProptypes) => store);

    return (
        <>
            {currentWebsite ? <>
                <div className='aioa_dashboard-init-msg-block d-flex align-items-center justify-content-center'>
                    <div className='upgrade-to-paid-popup-wrapper text-center rounded-4 bg-white shadow-lg p-5'>
                        <p className='fs-4 fw-bold'>
                            {t('Plan Expired', { domain: currentWebsite.domain, planName: currentWebsitePlanStatus.planName, planExpiredDate: currentWebsitePlanStatus.planExpiredData })}
                        </p>
                        {currentWebsite.expired_package_detail && currentWebsite.expired_package_detail.length > 0 && (
                            <>
                                {user && user.is_multiple_domain === 1 ? (
                                    <PaymentButtonBlock
                                        buttonType={2}
                                        activePlanType={2}
                                        activePlanDomain={currentWebsite.domain}
                                        activePlanInterval={currentWebsite.expired_package_detail[0].subscr_interval}
                                        activePlanPrice={currentWebsite.expired_package_detail[0].final_price}
                                        websiteID={currentWebsite.id}
                                        activeUserID={currentWebsite.user_id}
                                        activePlanID={currentWebsite.expired_package_detail[0].package_id}
                                        buttonBigSize={true}
                                        buttonVariant={`primary`}
                                        buttonLabel={`Renew Now Button`}
                                    />
                                ) : (
                                    <PaymentButtonBlock
                                        buttonType={2}
                                        activePlanType={1}
                                        activePlanDomain={currentWebsite.domain}
                                        activePlanInterval={currentWebsite.expired_package_detail[0].subscr_interval}
                                        activePlanPrice={currentWebsite.expired_package_detail[0].final_price}

                                        websiteID={currentWebsite.id}
                                        activeUserID={currentWebsite.user_id}
                                        activePlanID={currentWebsite.expired_package_detail[0].package_id}
                                        buttonBigSize={true}
                                        buttonVariant={`primary`}
                                        buttonLabel={`Renew Now Button`}
                                    />
                                )}

                            </>
                        )}
                    </div>
                </div>
            </> : <></>}


        </>
    )
}

export default ExpiredVersionOverlay;