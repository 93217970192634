import { Alert, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { StoreProptypes } from "../../PropTypes/StoreProptypes";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import "../../Assets/scss/components/website-plan-status-msg.scss";
import { useLocation, useNavigate } from "react-router-dom";
import PaymentButtonBlock from "../PaymentButton";

const WebsitePlanStatusMsg = () => {

    // Redux Data
    const { currentWebsite, currentWebsitePlanStatus,user } = useSelector((store: StoreProptypes) => store);

    // Function
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();

    // State
    const [showWebsitePlanStatusMsg, setShowWebsitePlanStatusMsg] = useState(true);

    // Redirection
    

    useEffect(() => {
        /* if (currentWebsitePlanStatus.isExpired) {
            if (location.pathname === '/front/scripts' || location.pathname === '/front/scripts/') {
                navigate('/front');
            }
        } */

        if((currentWebsitePlanStatus.planExpiredData === null && currentWebsitePlanStatus.planName === null)) {

            const noPlanFoundCondition1: boolean = (location.pathname === '/front/scripts' || location.pathname === '/front/scripts/');
            const noPlanFoundCondition2: boolean = (location.pathname === '/front' || location.pathname === '/front/');
            const noPlanFoundCondition3: boolean = (location.pathname === '/front/widget-settings' || location.pathname === '/front/widget-settings/');
            const noPlanFoundCondition4: boolean = (location.pathname === '/front/manage-image-alt-text' || location.pathname === '/front/manage-image-alt-text/');
            const noPlanFoundCondition5: boolean = (location.pathname === '/front/addon-services/white-label-service' || location.pathname === '/front/addon-services/white-label-service/');
            const noPlanFoundCondition6: boolean = (location.pathname === '/front/addon-services/modify-accessibility-menu' || location.pathname === '/front/addon-services/modify-accessibility-menu/');
            const noPlanFoundCondition7: boolean = (location.pathname === '/front/addon-services/live-site-translation' || location.pathname === '/front/addon-services/live-site-translation/');
            const noPlanFoundCondition8: boolean = (location.pathname === '/front/addon-services/pdfs' || location.pathname === '/front/addon-services/pdfs/');
            const noPlanFoundFinalCondition: boolean = noPlanFoundCondition1 || noPlanFoundCondition2 || noPlanFoundCondition3 || noPlanFoundCondition4 || noPlanFoundCondition5 || noPlanFoundCondition6 || noPlanFoundCondition7 || noPlanFoundCondition8;

            //console.info("noPlanFoundCondition ==>", noPlanFoundCondition1, noPlanFoundCondition2, noPlanFoundCondition3, noPlanFoundCondition4, noPlanFoundCondition5, noPlanFoundCondition6, noPlanFoundCondition7, noPlanFoundCondition8);
            if(noPlanFoundFinalCondition) {
                navigate('/front/manage-plan');
            }

        }

    }, [currentWebsite])

    return (
        <>
            {(currentWebsite && !(
                location.pathname === '/front/websites' || location.pathname === '/front/websites/' ||
                location.pathname === '/front/manage-plan' || location.pathname === '/front/manage-plan/' ||
                location.pathname.includes('/front/package') || location.pathname.includes('/front/web/package')
            )) && (
                    <>
                        {(currentWebsitePlanStatus.isTrial && currentWebsite.current_package_only && currentWebsite.current_package_only.length > 0 && currentWebsite.current_package_only[0].is_pre_trial !== 1) || currentWebsitePlanStatus.isExpired && (
                            <>
                                <Alert className='aioa_dashboard-plan-status-alert' onClose={() => setShowWebsitePlanStatusMsg(false)} variant="danger">
                                    <div className="aioa_dashboard-plan-status-alert-text">
                                        {currentWebsitePlanStatus.isExpired ? (
                                            <>
                                                {t('Plan Expired', { domain: currentWebsite.domain, planName: currentWebsitePlanStatus.planName, planExpiredDate: currentWebsitePlanStatus.planExpiredData })}
                                            </>
                                        ) : (
                                            <>
                                                {t('Trial Plan', { planName: currentWebsitePlanStatus.planName, planExpiredDate: currentWebsitePlanStatus.planExpiredData })}
                                            </>
                                        )}
                                    </div>
                                    <div className='aioa_dashboard-plan-status-alert-btn'>
                                        {currentWebsitePlanStatus.isExpired ? (
                                            <>
                                                {currentWebsite && currentWebsite.expired_package_detail && currentWebsite.expired_package_detail.length > 0 && (
                                                    <>
                                                        {user && user.is_multiple_domain === 2 ? (
                                                            <>
                                                            <PaymentButtonBlock
                                                                buttonType={4}
                                                                activePlanType={2}
                                                                activePlanDomain={currentWebsite.domain}
                                                                activePlanInterval={currentWebsite.expired_package_detail[0].subscr_interval}
                                                                activePlanPrice={currentWebsite.expired_package_detail[0].final_price}
                                                                websiteID={currentWebsite.id}
                                                                activeUserID={currentWebsite.user_id}
                                                                activePlanID={currentWebsite.expired_package_detail[0].package_id}
                                                                buttonBigSize={true}
                                                                buttonVariant={`light`}
                                                                buttonLabel={`Renew Now Button`}
                                                            />
                                                            
                                                            </>

                                                        ):(
                                                            <>
                                                            <PaymentButtonBlock
                                                                buttonType={2}
                                                                activePlanType={2}
                                                                activePlanDomain={currentWebsite.domain}
                                                                activePlanInterval={currentWebsite.expired_package_detail[0].subscr_interval}
                                                                activePlanPrice={currentWebsite.expired_package_detail[0].final_price}

                                                                websiteID={currentWebsite.id}
                                                                activeUserID={currentWebsite.user_id}
                                                                activePlanID={currentWebsite.expired_package_detail[0].package_id}
                                                                buttonBigSize={true}
                                                                buttonVariant={`light`}
                                                                buttonLabel={`Renew Now Button`}
                                                            />
                                                            {/* <h1>overlay</h1> */}
                                                            </>
                                                            
                                                            
                                                        )}

                                                    </>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                {currentWebsite && currentWebsite.current_package_only && currentWebsite.current_package_only.length > 0 && (
                                                    <>
                                                        <PaymentButtonBlock
                                                            buttonType={2}
                                                            activePlanType={3}
                                                            activePlanDomain={currentWebsite.domain}
                                                            activePlanInterval={currentWebsite.current_package_only[0].subscr_interval}
                                                            activePlanPrice={currentWebsite.current_package_only[0].final_price}

                                                            websiteID={currentWebsite.id}
                                                            activeUserID={currentWebsite.user_id}
                                                            activePlanID={currentWebsite.current_package_only[0].package_id}
                                                            buttonBigSize={true}
                                                            buttonVariant={`light`}
                                                            buttonLabel={`Purchase Now Button`}
                                                        />
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </div>
                                </Alert>
                            </>
                        )}
                    </>
                )}
        </>
    )
}

export default WebsitePlanStatusMsg;
