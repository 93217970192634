import { useTranslation } from "react-i18next";
import { UserOrderItemPropTypes } from "../../PropTypes/UserOrderDataPropTypes";
import parseISODate from "../../Helpers/parseISODate";
import { Button, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import OrderListCellActionButtons from "./OrderListCellActionButtons";

interface PropTypes {
    item: UserOrderItemPropTypes,
    index: number,
    totalOrderItems: number
}

const ExtensionsPlanOrderCellBlock = (props: PropTypes) => {

    // PropTypes
    const { item, index, totalOrderItems } = props;

    // Functions
    const { t } = useTranslation();



    return (
        <>
            <div className="aioa_dashboard-table-tr">

                <div className="aioa_dashboard-table-td tcw-100 aioa_dashboard-table-cell-sidegap">
                    <div className='aioa_dashboard-table-td-cell-title d-xl-none' aria-hidden="true">{t("Orders #")}</div>
                    <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Orders #").toString()}>
                        {[...Array((5 - (item.id).toString().length))].map((i) => 0)}{item.id}
                    </div>
                </div>

                <div className="aioa_dashboard-table-td aioa_dashboard-table-cell-sidegap d-none d-xl-flex">
                    <div className='aioa_dashboard-table-td-cell-title d-xl-none' aria-hidden="true">{t("Description")}</div>
                    <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Description").toString()}>
                        <div className="aioa_dashboard-order-plan-details">
                            <strong>Extensions {t('Plan Table Label')}</strong> : {item.name}
                            {(item.subscr_interval === 'M' ? (
                                <>
                                    &nbsp;<strong className="text-primary">({t("Monthly Plan Type")})</strong>
                                </>
                            ) : (
                                <>
                                    &nbsp;<strong className="text-primary">({t("Annually Plan Type")})</strong>
                                </>
                            ))}
                            {item.is_trial_period === 1 && (<>&nbsp;- <strong className="text-danger">{t("Trial Plan Label")}</strong> </>)}
                        </div>
                        <div className="aioa_dashboard-order-domain">
                            <strong>{t('Email')}</strong> : {item.email}
                        </div>
                    </div>
                </div>

                <div className="aioa_dashboard-table-td aioa_dashboard-table-cell-sidegap d-xl-none">
                    <div className='aioa_dashboard-table-td-cell-title d-xl-none' aria-hidden="true">{t("Add-On Table label")}</div>
                    <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Add-On Table label").toString()}>
                        <div className="aioa_dashboard-order-plan-details">
                            {item.name} {item.is_trial_period === 1 && (<>&nbsp;- <strong className="text-danger">{t("Trial Plan Label")}</strong> </>)}
                        </div>
                    </div>
                </div>

                <div className="aioa_dashboard-table-td aioa_dashboard-table-cell-sidegap d-xl-none">
                    <div className='aioa_dashboard-table-td-cell-title d-xl-none' aria-hidden="true">{t("Plan Interval")}</div>
                    <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Plan Interval").toString()}>
                        <div className="aioa_dashboard-order-plan-details">
                            {(item.subscr_interval === 'M' ? (
                                <>
                                    <strong className="text-primary">{t("Monthly Plan Type")}</strong>
                                </>
                            ) : (
                                <>
                                    <strong className="text-primary">{t("Annually Plan Type")}</strong>
                                </>
                            ))}
                        </div>
                    </div>
                </div>

                <div className="aioa_dashboard-table-td aioa_dashboard-table-cell-sidegap d-xl-none">
                    <div className='aioa_dashboard-table-td-cell-title d-xl-none' aria-hidden="true">{t("Email")}</div>
                    <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Email").toString()}>
                        <div className="aioa_dashboard-order-domain">
                            {item.email}
                        </div>
                    </div>
                </div>

                <div className="aioa_dashboard-table-td tcw-200 text-center aioa_dashboard-table-cell-sidegap">
                    <div className='aioa_dashboard-table-td-cell-title d-xl-none' aria-hidden="true">{t("Orders Date")}</div>
                    <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Orders Date").toString()}>
                        {item.start_date ? parseISODate(item.start_date) : '-'}
                    </div>
                </div>

                <div className="aioa_dashboard-table-td tcw-200 text-center aioa_dashboard-table-cell-sidegap">
                    <div className='aioa_dashboard-table-td-cell-title d-xl-none' aria-hidden="true">{t("Amount (USD)")}</div>
                    <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Amount (USD)").toString()}>
                        ${((item.final_price)).toFixed(2)}
                    </div>
                </div>

                <div className="aioa_dashboard-table-td tcw-200 text-center aioa_dashboard-table-cell-sidegap">
                    <div className='aioa_dashboard-table-td-cell-title d-xl-none' aria-hidden="true">{t("Status")}</div>
                    <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Status").toString()}>
                        {(item.payment_status === "Failed") ? (
                            <>
                                <Button className="aioa_dashboard-icon-btn">
                                    <div className="aioa_dashboard-btn-icon"><i className={`material-symbols-outlined`}>info</i></div>
                                    <div className="aioa_dashboard-btn-text">{t("Payment Failed")}</div>
                                </Button>
                            </>
                        ) : (item.payment_status === "Canceled") ? (
                            <>
                                <div className='text-success'>
                                    <strong>{t("Paid Payment Status")}</strong>&nbsp;
                                    <span className='text-danger'>({t('canceled label')})</span>
                                </div>
                                <div className="text-danger"><strong>{item.cancel_date ? parseISODate(item.cancel_date) : '-'}</strong></div>
                            </>
                        ) : (
                            <>
                                <div className='text-success'><strong>{t("Paid Payment Status")}</strong></div>
                            </>
                        )}
                    </div>
                </div>

                <div className="aioa_dashboard-table-td tcw-200 text-center aioa_dashboard-table-cell-sidegap">
                    <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Action").toString()}>
                        <OrderListCellActionButtons
                            orderItemIndex={index}
                            orderItemID={item.id}
                            paymentStatus={`${item.payment_status}`}
                            purchaseURL={`${item.purchase_url}`}
                            totalOrderItems={totalOrderItems}
                        />
                    </div>
                </div>

            </div>
        </>
    )
}

export default ExtensionsPlanOrderCellBlock;