const baseURL = `${process.env.REACT_APP_BASE_URL}/api`

const headers = {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
}


const AutoLoginApi = async (
    token: string,
    userID?: string
): Promise<any> => {

    const url = `${baseURL}/auth-login`;

    const data = new FormData();

    data.append("auth_code", token);
    userID && data.append("user_id", userID);

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: headers,
            body: data
        });
        return await response.json();
    } catch (error) {
        return Promise.reject;
    }
}

export { AutoLoginApi }