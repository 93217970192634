import { Card } from "react-bootstrap"
import { useSelector } from "react-redux";
import { StoreProptypes } from "../../PropTypes/StoreProptypes";
import parseISODate from "../../Helpers/parseISODate";
import { useTranslation } from "react-i18next";
import UpgradePlanList from "../PaymentButton/UpgradePlanList";
import StrikePrice from '../../Helpers/strikePrice';
import PaymentButtonBlock from "../PaymentButton";
import { useLocation } from "react-router-dom";

const NormalUserCurrentActivePlanInfo = () => { // only Normal user Plan Details

    // Functions
    const { t } = useTranslation();
    const location = useLocation();

    // Redux Data
    const { currentWebsite, user } = useSelector((store: StoreProptypes) => store);

    
   


    return (
        <>
            {currentWebsite && currentWebsite.current_package_only && currentWebsite.current_package_only.length > 0 && (
                <>
                    <Card className="aioa_dashboard-plan-info-box normal-user">
                        <Card.Body>
                            <div className="aioa_dashboard-plan-info-box-label">{t('Current Plan')}</div>
                            <div className="aioa_dashboard-plan-info-box-left">
                                <h4 className="text-primary mb-1">{currentWebsite.current_package_only[0].name} Plan</h4>
                                {currentWebsite.is_free_widget !== 1 && (
                                    <>
                                        {(currentWebsite.current_package_only[0].cancel_date === null || currentWebsite.current_package_only[0].cancel_date === '') ? (
                                            <>
                                                <p className="text-danger mb-0"><strong>{t('Plan Expired Information', { expiredDate: parseISODate(currentWebsite?.current_package_only[0].end_date) })} </strong></p>
                                            </>
                                        ) : (
                                            <>
                                                <p className="text-danger mb-0"><strong>{t(`Plan will be cancel on ${parseISODate(currentWebsite.current_package_only[0].end_date)}`)}.</strong></p>
                                            </>
                                        )}
                                    </>
                                )}
                            </div>
                            <div className="aioa_dashboard-plan-info-box-right">
                                <div className="aioa_dashboard-plan-info-box-price">

                                    {currentWebsite.current_package_only[0].subscr_interval === 'Y' ? (
                                        <>
                                            <strong className="original-price">${(currentWebsite.current_package_only[0].final_price).toFixed(2)}<span>/{t('Per Year')}</span></strong>
                                        </>
                                    ) : (
                                        <>
                                            <strong className="original-price">${(currentWebsite.current_package_only[0].final_price).toFixed(2)}<span>/{t('Per Month')}</span></strong>
                                        </>
                                    )}
                                </div>

                                {(location.pathname === '/front/settings' || location.pathname === '/front/settings/') && (currentWebsite.current_package_only[0].cancel_date === null || currentWebsite.current_package_only[0].cancel_date === '') && (
                                    <>
                                        <div className="aioa_dashboard-plan-info-box-btn">
                                            <PaymentButtonBlock
                                                buttonType={3}
                                                activePlanType={1}
                                                activePlanDomain={currentWebsite.domain}
                                                activePlanInterval={currentWebsite.current_package_only[0].subscr_interval}
                                                activePlanPrice={currentWebsite.current_package_only[0].final_price}
                                                activePlanOfferPrice={currentWebsite.cancel_discount_price}
                                                activePlanOfferPr={currentWebsite.cancel_discount_percentage}
                                                websiteID={currentWebsite.id}
                                                activeUserID={currentWebsite.user_id}
                                                activePlanID={currentWebsite.current_package_only[0].package_id}
                                                buttonBigSize={false}
                                                buttonVariant={`link`}
                                                buttonLabel={`Cancel Plan`}
                                                isShowDiscount={false}
                                            />
                                        </div>
                                    </>
                                )}

                            </div>
                        </Card.Body>
                    </Card>
                </>
            )}
        </>
    )
}

const PrePurchasedPartnerUserCurrentActivePlanInfo = () => { // only PrePurchased Partner user Plan Details

    // Functions
    const { t } = useTranslation();
    const location = useLocation();

    // Redux Data
    const { currentWebsite } = useSelector((store: StoreProptypes) => store);

    return (
        <>
            {currentWebsite && currentWebsite.current_package_only && currentWebsite.current_package_only.length > 0 && (
                <>
                    <Card className="aioa_dashboard-plan-info-box prepurchased-Partner">
                        <Card.Body>
                            <div className="aioa_dashboard-plan-info-box-label">{t('Current Plan')}</div>
                            <div className="aioa_dashboard-plan-info-box-left">
                                <h4 className="text-primary mb-1">{currentWebsite.current_package_only[0].name} Plan</h4>
                                {currentWebsite.is_free_widget !== 1 && (
                                    <>
                                        <p className="text-danger mb-0"><strong>{t('Plan Expired Information', { expiredDate: parseISODate(currentWebsite?.current_package_only[0].end_date) })} </strong></p>
                                    </>
                                )}
                            </div>
                            <div className="aioa_dashboard-plan-info-box-right">
                                <div className="aioa_dashboard-plan-info-box-price">
                                    {currentWebsite.current_package_only[0].subscr_interval === 'Y' ? (
                                        <>
                                            <strong className="original-price">${(currentWebsite.current_package_only[0].final_price).toFixed(2)}/{t('Per Year')}</strong>
                                        </>
                                    ) : (
                                        <>
                                            <strong className="original-price">${(currentWebsite.current_package_only[0].final_price).toFixed(2)}/{t('Per Month')}</strong>
                                        </>
                                    )}
                                </div>
                                {(location.pathname === '/front/settings' || location.pathname === '/front/settings/') && (currentWebsite.current_package_only[0].cancel_date === null || currentWebsite.current_package_only[0].cancel_date === '') && (
                                    <>
                                        <div className="aioa_dashboard-plan-info-box-btn">
                                            <PaymentButtonBlock
                                                buttonType={3}
                                                activePlanType={3}
                                                activePlanDomain={currentWebsite.domain}
                                                activePlanInterval={currentWebsite.current_package_only[0].subscr_interval}
                                                activePlanPrice={currentWebsite.current_package_only[0].final_price}
                                                activePlanOfferPrice={currentWebsite.cancel_discount_price}
                                                activePlanOfferPr={currentWebsite.cancel_discount_percentage}
                                                websiteID={currentWebsite.id}
                                                activeUserID={currentWebsite.user_id}
                                                activePlanID={currentWebsite.current_package_only[0].package_id}
                                                buttonBigSize={false}
                                                buttonVariant={`link`}
                                                buttonLabel={`Cancel Plan`}
                                                isShowDiscount={false}
                                            />
                                                                            

                                        </div>
                                    </>
                                )}
                            </div>
                        </Card.Body>
                    </Card>
                </>
            )}
        </>
    )
}

const NormalPartnerUserCurrentActivePlanInfo = () => { // only Normal Partner user Plan Details

    // Functions
    const { t } = useTranslation();
    const location = useLocation();

    // Redux Data
    const { currentWebsite } = useSelector((store: StoreProptypes) => store);

    return (
        <>
            {currentWebsite && currentWebsite.current_package_only && currentWebsite.current_package_only.length > 0 && (
                <>
                    <Card className="aioa_dashboard-plan-info-box normal-partner">
                        <Card.Body>
                            <div className="aioa_dashboard-plan-info-box-label">{t('Current Plan')}</div>
                            <div className="aioa_dashboard-plan-info-box-left">
                                <h4 className="text-primary mb-1">{currentWebsite.current_package_only[0].name} Plan</h4>
                                {currentWebsite.is_free_widget !== 1 && (
                                    <>
                                        <p className="text-danger mb-0"><strong>{t('Plan Expired Information', { expiredDate: parseISODate(currentWebsite?.current_package_only[0].end_date) })} </strong></p>
                                    </>
                                )}
                            </div>
                            <div className="aioa_dashboard-plan-info-box-right">
                                <div className="aioa_dashboard-plan-info-box-price">
                                    {currentWebsite.current_package_only[0].subscr_interval === 'Y' ? (
                                        <>
                                            <strong className="original-price">${(currentWebsite.current_package_only[0].final_price).toFixed(2)}/{t('Per Year')}</strong>
                                        </>
                                    ) : (
                                        <>
                                            <strong className="original-price">${(currentWebsite.current_package_only[0].final_price).toFixed(2)}/{t('Per Month')}</strong>
                                        </>
                                    )}
                                </div>

                                {(location.pathname === '/front/settings' || location.pathname === '/front/settings/') && (currentWebsite.current_package_only[0].cancel_date === null || currentWebsite.current_package_only[0].cancel_date === '') && (
                                    <>
                                        <div className="aioa_dashboard-plan-info-box-btn">
                                            <PaymentButtonBlock
                                                buttonType={3}
                                                activePlanType={1}
                                                activePlanDomain={currentWebsite.domain}
                                                activePlanInterval={currentWebsite.current_package_only[0].subscr_interval}
                                                activePlanPrice={currentWebsite.current_package_only[0].final_price}
                                                activePlanOfferPrice={currentWebsite.cancel_discount_price}
                                                activePlanOfferPr={currentWebsite.cancel_discount_percentage}
                                                websiteID={currentWebsite.id}
                                                activeUserID={currentWebsite.user_id}
                                                activePlanID={currentWebsite.current_package_only[0].package_id}
                                                buttonBigSize={false}
                                                buttonVariant={`link`}
                                                buttonLabel={`Cancel Plan`}
                                                isShowDiscount={false}
                                            />
                                            
                                        </div>
                                    </>
                                )}

                            </div>
                        </Card.Body>
                    </Card>
                </>
            )}
        </>
    )
}

const MultiStoreUserCurrentActivePlanInfo = () => { // only Multi Store user Plan Details

    // Functions
    const { t } = useTranslation();
    const location = useLocation();

    // Redux Data
    const { currentWebsite } = useSelector((store: StoreProptypes) => store);

    return (
        <>
            {currentWebsite && currentWebsite.current_package_only && currentWebsite.current_package_only.length > 0 && (
                <>
                    <Card className="aioa_dashboard-plan-info-box normal-user">
                        <Card.Body>
                            <div className="aioa_dashboard-plan-info-box-label">{t('Current Plan')}</div>
                            <div className="aioa_dashboard-plan-info-box-left">
                                <h4 className="text-primary mb-1">{currentWebsite.current_package_only[0].name} Plan</h4>
                                {currentWebsite.is_free_widget !== 1 && (
                                    <>
                                        {(currentWebsite.current_package_only[0].cancel_date === null || currentWebsite.current_package_only[0].cancel_date === '') ? (
                                            <>
                                                <p className="text-danger mb-0"><strong>{t('Plan Expired Information', { expiredDate: parseISODate(currentWebsite?.current_package_only[0].end_date) })} </strong></p>
                                            </>
                                        ) : (
                                            <>
                                                <p className="text-danger mb-0"><strong>{t(`Plan will be cancel on ${parseISODate(currentWebsite.current_package_only[0].end_date)}`)}.</strong></p>
                                            </>
                                        )}
                                    </>
                                )}
                            </div>
                            <div className="aioa_dashboard-plan-info-box-right">
                                <div className="aioa_dashboard-plan-info-box-price">
                                    {currentWebsite.current_package_only[0].subscr_interval === 'Y' ? (
                                        <>
                                            <strong className="original-price">${(currentWebsite.current_package_only[0].final_price).toFixed(2)}<span>/{t('Per Year')}</span></strong>
                                        </>
                                    ) : (
                                        <>
                                            <strong className="original-price">${(currentWebsite.current_package_only[0].final_price).toFixed(2)}<span>/{t('Per Month')}</span></strong>
                                        </>
                                    )}
                                </div>


                                {(location.pathname === '/front/settings' || location.pathname === '/front/settings/') && (currentWebsite.current_package_only[0].cancel_date === null || currentWebsite.current_package_only[0].cancel_date === '') && (
                                    <>
                                        <div className="aioa_dashboard-plan-info-box-btn">
                                            <PaymentButtonBlock
                                                buttonType={3}
                                                activePlanType={2}
                                                activePlanDomain={currentWebsite.domain}
                                                activePlanInterval={currentWebsite.current_package_only[0].subscr_interval}
                                                activePlanPrice={currentWebsite.current_package_only[0].final_price}
                                                activePlanOfferPrice={currentWebsite.cancel_discount_price}
                                                activePlanOfferPr={currentWebsite.cancel_discount_percentage}
                                                websiteID={currentWebsite.id}
                                                activeUserID={currentWebsite.user_id}
                                                activePlanID={currentWebsite.current_package_only[0].package_id}
                                                buttonBigSize={false}
                                                buttonVariant={`link`}
                                                buttonLabel={`Cancel Plan`}
                                                isShowDiscount={false}
                                            />
                                            
                                        </div>
                                    </>
                                )}
                            </div>
                        </Card.Body>
                    </Card>
                </>
            )}
        </>
    )
}

const AllUserOnlyActivePlanInfo = () => {

    // Redux Data
    const { currentWebsite, user, currentWebsitePlanStatus } = useSelector((store: StoreProptypes) => store);
    const location = useLocation();
    const { t } = useTranslation();
    const finalPrice = Number(currentWebsite?.current_package_only?.[0]?.package_monthly_price) || 0;
    return (
        <>
            {currentWebsite && (
                <>
                    {user && (
                        <>
                            {user.department === 4 ? ( // For Partner User Only
                                <>
                                    {(user.is_multiple_domain === 1) ? (
                                        <>
                                             
                                                <>
                                                    <PrePurchasedPartnerUserCurrentActivePlanInfo />
                                                </>
                                                
                                                <>
                                                
                                                    {!(location.pathname === '/front/settings' || location.pathname === '/front/settings/')
                                                        && (
                                                            <>
                                                                <Card className="aioa_dashboard-upgrade-plan-info-box">
                                                                    <Card.Body>
                                                                        <div className="aioa_dashboard-upgrade-plan-info-box-title">
                                                                            <div className="h4 text-primary mb-1">{t('Select Plan')}</div>
                                                                            <div className="">{t('Select your desire plan based on your website pageview')}</div>
                                                                        </div>
                                                                        <UpgradePlanList
                                                                            activePlanType={3}
                                                                            activePlanDomain={currentWebsite.domain}
                                                                            activePlanInterval={currentWebsite.current_package_only[0].subscr_interval}
                                                                            activePlanPrice={finalPrice}
                                                                            
                                                                        />
                                                                        {/* <h1>jii</h1> */}
                                                                       
                                                                    </Card.Body>
                                                                </Card>
                                                            </>
                                                        )}
                                                </>
                                            
                                        </>
                                    ) : ( // Normal Partner plan with upgrade one step up
                                        <>
                                            <NormalPartnerUserCurrentActivePlanInfo />
                                            {!(location.pathname === '/front/settings' || location.pathname === '/front/settings/')
                                                && (
                                                    <>
                                                        {!(currentWebsite.current_package_only[0].package_id === 10 && currentWebsite.current_package_only[0].subscr_interval === "Y" ) && (
                                                            <>
                                                                <Card className="aioa_dashboard-upgrade-plan-info-box">
                                                                    <Card.Body>
                                                                        <div className="aioa_dashboard-upgrade-plan-info-box-title">
                                                                            <div className="h4 text-primary">{t('Upgrade Plan Button')}</div>
                                                                            <div className="">{t('Select a Plan to Upgrade')}</div>
                                                                        </div>
                                                                        <UpgradePlanList
                                                                            activePlanType={1}
                                                                            activePlanDomain={currentWebsite.domain}
                                                                            activePlanInterval={currentWebsite.current_package_only[0].subscr_interval}
                                                                            activePlanPrice={currentWebsite.current_package_only[0].package_monthly_price}
                                                                        />
                                                                    </Card.Body>
                                                                </Card>
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                        </>
                                    )}
                                </>
                            ) : (
                                <>
                                    {user.is_multiple_domain === 2 ? ( // Sliver Gold Plan with Add New plan
                                        <>
                                            {user.is_multi_domain_purchased === 1 ? (
                                                <>
                                                  <MultiStoreUserCurrentActivePlanInfo />
                                                </>
                                            ) : (
                                                <>
                                                    <MultiStoreUserCurrentActivePlanInfo />

                                                    {!(location.pathname === '/front/settings' || location.pathname === '/front/settings/') && (
                                                        <>
                                                            <Card className="aioa_dashboard-upgrade-plan-info-box">
                                                                <Card.Body>
                                                                    <div className="aioa_dashboard-upgrade-plan-info-box-title">
                                                                        <div className="h4 text-primary mb-1">{t("Select Plans")}</div>
                                                                        <div className="">{t("Select your desire plan based on your website pageview")}</div>
                                                                    </div>
                                                                    <UpgradePlanList
                                                                        activePlanType={2}
                                                                        activePlanDomain={currentWebsite.domain}
                                                                        activePlanInterval={currentWebsite.current_package_only[0].subscr_interval}
                                                                        activePlanPrice={currentWebsite.current_package_only[0].package_monthly_price}
                                                                    />
                                                                </Card.Body>
                                                            </Card>
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </>
                                    ) : ( // Normal Plan with one stepup upgrade option
                                        <>
                                            <NormalUserCurrentActivePlanInfo />
                                            {!(location.pathname === '/front/settings' || location.pathname === '/front/settings/')
                                                && (currentWebsitePlanStatus.planType !== "enterprise")
                                                && (
                                                    <>

                                                        {!(currentWebsite.current_package_only[0].package_id === 10 && currentWebsite.current_package_only[0].subscr_interval === "Y") && (
                                                            <>
                                                                <Card className="aioa_dashboard-upgrade-plan-info-box">
                                                                    <Card.Body>
                                                                        <div className="aioa_dashboard-upgrade-plan-info-box-title">
                                                                            <div className="h4 text-primary">{t('Upgrade Plan Button')}</div>
                                                                            <div className="">{t('Select a Plan to Upgrade')}</div>
                                                                        </div>
                                                                        <UpgradePlanList
                                                                            activePlanType={1}
                                                                            activePlanDomain={currentWebsite.domain}
                                                                            activePlanInterval={currentWebsite.current_package_only[0].subscr_interval}
                                                                            activePlanPrice={currentWebsite.current_package_only[0].package_monthly_price}
                                                                        />
                                                                    </Card.Body>
                                                                </Card>
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                        </>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </>
            )}

        </>
    )
}

export default AllUserOnlyActivePlanInfo;
