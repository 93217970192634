import { LOADING, SET_MESSAGE, SET_DOMAIN_LIMIT_DATA } from "../../Actions/Types";
import { getBillingHistoryApi, sendInvoiceEmailApi, invoicePDFApi } from "../../Api/UserOrderApi";
import { DomainDetailsService } from "../DomainService/DomainDetails";
import { getUserDetailsService } from "../UserAction/LoginActionService";
import { UserOrderItemPropTypes } from "../../PropTypes/UserOrderDataPropTypes";
import { UserPropTypes } from "../../PropTypes/UserPropTypes";

const getBillingHistoryService = (
    accessToken: string,
    limit: number,
    offset: number,
    domainID: number,
    orderID: string,
    startDate: string | null,
    endDate: string | null,
    setTotalRecord: Function,
    dispatch: Function,
    t: Function,
    navigate: Function,
    setOrderList: Function,
    isNewWebsiteFlag: boolean,
    isUpdateUserFlag: boolean,
    isUpdateWebsiteFlag: boolean,
    updateWebsiteID: number,
    isUpdateDomainLimitFlag: boolean = false, //change:avani
    user: UserPropTypes | null = null
) => {

    // Set Loading
    dispatch({
        type: LOADING,
        payload: true,
    });

    getBillingHistoryApi(accessToken, limit, offset, domainID, orderID, startDate, endDate).then(async (response) => {


        if (response.status === 200) {

            if (isUpdateWebsiteFlag || isNewWebsiteFlag || isUpdateUserFlag || isUpdateDomainLimitFlag) {

                getUserDetailsService(accessToken, dispatch, t).then(async (userRes) => {

                    if (userRes.status === 200) {

                        if (updateWebsiteID !== 0) {
                            console.log("from user order service");

                            DomainDetailsService(accessToken, updateWebsiteID, dispatch, t).then(async (res) => {

                                if (res.status === 200) {

                                    // Set Order Data

                                    setOrderList(response.Data);
                                    setTotalRecord(response.total_records);

                                    navigate('/front/orders');

                                    // Set Loading
                                    dispatch({
                                        type: LOADING,
                                        payload: false,
                                    });

                                }
                            });

                        } else {
                            if (isUpdateDomainLimitFlag && user) {
                                 //todo:here
                                let currentPackageId = user?.current_active_package_id;

                                if (currentPackageId) {
                                    let orderList: UserOrderItemPropTypes[] | undefined;
                                    let currentPackageId = user?.current_active_package_id

                                    // Assign response.Data to orderList
                                    orderList = response.Data as UserOrderItemPropTypes[];
                                    const foundItem = orderList.find(item => item.id === currentPackageId);
                                    if (foundItem) {
                                        dispatch({ //todo:here
                                            type: SET_DOMAIN_LIMIT_DATA,
                                            payload: {
                                                currentPackageId: currentPackageId,
                                                currentPackageDomainLimit: foundItem.domain_limit,
                                                currentPackageChildDomainsCount: foundItem.child_domains.length,
                                            }
                                        });
                                    }
                                }
                            }
                            else {
                                // Set Order Data
                                setOrderList(response.Data);
                                setTotalRecord(response.total_records);

                                navigate('/front/orders');
                            }

                            // Set Loading
                            dispatch({
                                type: LOADING,
                                payload: false,
                            });

                        }

                    }
                })

            } else {

                // Set Order Data
                setOrderList(response.Data);
                setTotalRecord(response.total_records)

                // Set Loading
                dispatch({
                    type: LOADING,
                    payload: false,
                });
            }

        }

    })
}

const sendInvoiceEmailService = (
    accessToken: string,
    OrderId: number,
    userEmail: string,
    dispatch: Function,
    t: Function,
    setConfirmEmailModelHide: Function
) => {

    sendInvoiceEmailApi(accessToken, OrderId, userEmail).then(response => {
        if (response.Data) {
            setConfirmEmailModelHide();
            dispatch({
                type: SET_MESSAGE,
                payload: {
                    "toast_message": t(`Invoice Success Msg`, { returnObjects: true }),
                    "toast_type": "success"
                }
            })
        }
    })
}

const invoicePDFService = (
    accessToken: string,
    OrderId: number,
    loadingDownload: boolean[],
    seLoadingDownload: Function,
    index: number,
    length: number
) => {
    const temp = loadingDownload;
    temp.splice(index, 1, true);
    seLoadingDownload([...temp]);

    invoicePDFApi(accessToken, OrderId).then(response => {
        if (response.data) {
            window.open(response.data, '_blank', 'noreferrer');
            seLoadingDownload(new Array(length).fill(false));
        }
    })
}


export { getBillingHistoryService, sendInvoiceEmailService, invoicePDFService }